import React, { useEffect, useState } from "react";

import { Menu, theme, Avatar } from "antd";
import { Link, useLocation } from "react-router-dom";
import "./index.css";
import { STRINGS } from "../../utils/Enums";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome, faFileAlt, faUser, faUserCircle, faWallet, faCreditCard, faStar, faBell, faHeadset, faCog, faGlobe, faMapMarkerAlt, faMap, faTags, faList, faPaperPlane
} from '@fortawesome/free-solid-svg-icons';


const AsideMenu = ({ collapsed }) => {
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    const pathnames = location.pathname.split("/").filter(Boolean);
    setSelectedKeys([pathnames[0]]);
  }, [location, collapsed]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "calc(100% - 100px)",
          overflowY: 'auto'
        }}
      >
        <Menu
          style={{ height: "100%", borderRight: 0 }}
          selectedKeys={selectedKeys}
          mode="inline"
        >
          <Menu.Item
            className="menuItem"
            key="dashboard"
            icon={
              <div className="menuIcon">
                <FontAwesomeIcon
                  icon={faHome}
                  style={{ color: "#ffffff", fontSize: "12px" }}
                />
              </div>
            }
          >
            <Link
              className="menuLink"
              style={{
                textDecorationLine: "none",
                fontSize: "12px",
                fontFamily: STRINGS.FONTS.HELVITICA,
                fontWeight: "700",
                marginLeft: "12px",
              }}
              to={`${STRINGS.ROUTES.DASHBOARD}`}
            >
              Dashboard
            </Link>
          </Menu.Item>
          <Menu.Item
            className="menuItem"
            key="approval"
            icon={
              <div className="menuIcon">
                <FontAwesomeIcon
                  icon={faFileAlt}
                  style={{ color: "#ffffff", fontSize: "12px" }}
                />
              </div>
            }
          >
            <Link
              style={{
                textDecorationLine: "none",
                fontSize: "12px",
                fontFamily: STRINGS.FONTS.HELVITICA,
                fontWeight: "700",
                marginLeft: "12px",
              }}
              to={`${STRINGS.ROUTES.APPROVAL}`}
            >
              Approval
            </Link>
          </Menu.Item>
          <Menu.Item
            className="menuItem"
            key="concept"
            icon={
              <div className="menuIcon">
                <FontAwesomeIcon
                  icon={faUserCircle}
                  style={{ color: "#ffffff", fontSize: "12px" }}
                />
              </div>
            }
          >
            <Link
              style={{
                textDecorationLine: "none",
                fontSize: "12px",
                fontFamily: STRINGS.FONTS.HELVITICA,
                fontWeight: "700",
                marginLeft: "12px",
              }}
              to={`${STRINGS.ROUTES.CONCEPT}`}
            >
              Concept
            </Link>
          </Menu.Item>
          <Menu.Item
            className="menuItem"
            key="influencer"
            icon={
              <div className="menuIcon">
                <FontAwesomeIcon
                  icon={faUser}
                  style={{ color: "#ffffff", fontSize: "12px" }}
                />
              </div>
            }
          >
            <Link
              style={{
                textDecorationLine: "none",
                fontSize: "12px",
                fontFamily: STRINGS.FONTS.HELVITICA,
                fontWeight: "700",
                marginLeft: "12px",
              }}
              to={`${STRINGS.ROUTES.INFLUENCER}`}
            >
              Influencer
            </Link>
          </Menu.Item>
          <Menu.Item
            className="menuItem"
            key="request"
            icon={
              <div className="menuIcon">
                <FontAwesomeIcon
                  icon={faPaperPlane}
                  style={{ color: "#ffffff", fontSize: "12px" }}
                />
              </div>
            }
          >
            <Link
              style={{
                textDecorationLine: "none",
                fontSize: "12px",
                fontFamily: STRINGS.FONTS.HELVITICA,
                fontWeight: "700",
                marginLeft: "12px",
              }}
              to={`${STRINGS.ROUTES.REQUEST}`}
            >
              Request
            </Link>
          </Menu.Item>
          <Menu.Item
            className="menuItem"
            key="coverage"
            icon={
              <div className="menuIcon">
                <FontAwesomeIcon
                  icon={faPaperPlane}
                  style={{ color: "#ffffff", fontSize: "12px" }}
                />
              </div>
            }
          >
            <Link
              style={{
                textDecorationLine: "none",
                fontSize: "12px",
                fontFamily: STRINGS.FONTS.HELVITICA,
                fontWeight: "700",
                marginLeft: "12px",
              }}
              to={'/coverage'}
            >
              Coverage
            </Link>
          </Menu.Item>
          <Menu.Item
            className="menuItem"
            key="withdrawal"
            icon={
              <div className="menuIcon">
                <FontAwesomeIcon
                  icon={faWallet}
                  style={{ color: "#ffffff", fontSize: "12px" }}
                />
              </div>
            }
          >
            <Link
              style={{
                textDecorationLine: "none",
                fontSize: "12px",
                fontFamily: STRINGS.FONTS.HELVITICA,
                fontWeight: "700",
                marginLeft: "12px",
              }}
              to={`${STRINGS.ROUTES.WITHDRAWAL}`}
            >
              Withdrawal Request
            </Link>
          </Menu.Item>
          <Menu.Item
            className="menuItem"
            key="payment"
            icon={
              <div className="menuIcon">
                <FontAwesomeIcon
                  icon={faCreditCard}
                  style={{ color: "#ffffff", fontSize: "12px" }}
                />
              </div>
            }
          >
            <Link
              style={{
                textDecorationLine: "none",
                fontSize: "12px",
                fontFamily: STRINGS.FONTS.HELVITICA,
                fontWeight: "700",
                marginLeft: "12px",
              }}
              to={`${STRINGS.ROUTES.PAYMENTS}`}
            >
              Payments
            </Link>
          </Menu.Item>
          <Menu.Item
            className="menuItem"
            key="ratings"
            icon={
              <div className="menuIcon">
                <FontAwesomeIcon
                  icon={faStar}
                  style={{ color: "#ffffff", fontSize: "12px" }}
                />
              </div>
            }
          >
            <Link
              style={{
                textDecorationLine: "none",
                fontSize: "12px",
                fontFamily: STRINGS.FONTS.HELVITICA,
                fontWeight: "700",
                marginLeft: "12px",
              }}
              to={`${STRINGS.ROUTES.RATINGS}`}
            >
              Ratings{" "}
            </Link>
          </Menu.Item>
          <Menu.Item
            className="menuItem"
            key="notification"
            icon={
              <div className="menuIcon">
                <FontAwesomeIcon
                  icon={faBell}
                  style={{ color: "#ffffff", fontSize: "12px" }}
                />
              </div>
            }
          >
            <Link
              style={{
                textDecorationLine: "none",
                fontSize: "12px",
                fontFamily: STRINGS.FONTS.HELVITICA,
                fontWeight: "700",
                marginLeft: "12px",
              }}
              to={`${STRINGS.ROUTES.NOTIFICATION}`}
            >
              Notification{" "}
            </Link>
          </Menu.Item>
          <Menu.Item
            className="menuItem"
            key="support"
            icon={
              <div className="menuIcon">
                <FontAwesomeIcon
                  icon={faHeadset}
                  style={{ color: "#ffffff", fontSize: "12px" }}
                />
              </div>
            }
          >
            <Link
              style={{
                textDecorationLine: "none",
                fontSize: "12px",
                fontFamily: STRINGS.FONTS.HELVITICA,
                fontWeight: "700",
                marginLeft: "12px",
              }}
              to={`${STRINGS.ROUTES.SUPPORT}`}
            >
              Support
            </Link>
          </Menu.Item>
          <Menu.Item
            className="menuItem"
            key="setting"
            icon={
              <div className="menuIcon">
                <FontAwesomeIcon
                  icon={faCog}
                  style={{ color: "#ffffff", fontSize: "12px" }}
                />
              </div>
            }
          >
            <Link
              style={{
                textDecorationLine: "none",
                fontSize: "12px",
                fontFamily: STRINGS.FONTS.HELVITICA,
                fontWeight: "700",
                marginLeft: "12px",
              }}
              to={`/setting`}
            >
              Setting
            </Link>
          </Menu.Item>
          <Menu.Item
            className="menuItem"
            key="country"
            icon={
              <div className="menuIcon">
                <FontAwesomeIcon
                  icon={faGlobe}
                  style={{ color: "#ffffff", fontSize: "12px" }}
                />
              </div>
            }
          >
            <Link
              style={{
                textDecorationLine: "none",
                fontSize: "12px",
                fontFamily: STRINGS.FONTS.HELVITICA,
                fontWeight: "700",
                marginLeft: "12px",
              }}
              to={`/country`}
            >
              Country
            </Link>
          </Menu.Item>
          <Menu.Item
            className="menuItem"
            key="governorate"
            icon={
              <div className="menuIcon">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  style={{ color: "#ffffff", fontSize: "12px" }}
                />
              </div>
            }
          >
            <Link
              style={{
                textDecorationLine: "none",
                fontSize: "12px",
                fontFamily: STRINGS.FONTS.HELVITICA,
                fontWeight: "700",
                marginLeft: "12px",
              }}
              to={`/governorate`}
            >
              Governorate
            </Link>
          </Menu.Item>
          <Menu.Item
            className="menuItem"
            key="areas"
            icon={
              <div className="menuIcon">
                <FontAwesomeIcon
                  icon={faMap}
                  style={{ color: "#ffffff", fontSize: "12px" }}
                />
              </div>
            }
          >
            <Link
              style={{
                textDecorationLine: "none",
                fontSize: "12px",
                fontFamily: STRINGS.FONTS.HELVITICA,
                fontWeight: "700",
                marginLeft: "12px",
              }}
              to={`/areas`}
            >
              Areas
            </Link>
          </Menu.Item>
          <Menu.Item
            className="menuItem"
            key="category"
            icon={
              <div className="menuIcon">
                <FontAwesomeIcon
                  icon={faTags}
                  style={{ color: "#ffffff", fontSize: "12px" }}
                />
              </div>
            }
          >
            <Link
              style={{
                textDecorationLine: "none",
                fontSize: "12px",
                fontFamily: STRINGS.FONTS.HELVITICA,
                fontWeight: "700",
                marginLeft: "12px",
              }}
              to={`/category`}
            >
              Category
            </Link>
          </Menu.Item>
          <Menu.Item
            className="menuItem"
            key="subcategories"
            icon={
              <div className="menuIcon">
                <FontAwesomeIcon
                  icon={faList}
                  style={{ color: "#ffffff", fontSize: "12px" }}
                />
              </div>
            }
          >
            <Link
              style={{
                textDecorationLine: "none",
                fontSize: "12px",
                fontFamily: STRINGS.FONTS.HELVITICA,
                fontWeight: "700",
                marginLeft: "12px",
              }}
              to={`/subcategories`}
            >
              Subcategories
            </Link>
          </Menu.Item>
          {!collapsed && (
            <div style={{ padding: "10px 7px" }}>
              <Link
                style={{
                  textDecorationLine: "none",
                  color: "black",
                  fontFamily: STRINGS.FONTS.HELVITICA,
                  fontWeight: "700",
                  fontSize: "12px",
                  marginLeft: "12px",
                }}
                to={`${STRINGS.ROUTES.RTL}`}
              ></Link>
            </div>
          )}
        </Menu>
      </div>
    </>
  );
};

export default AsideMenu;
