import { createSlice } from "@reduxjs/toolkit";
export const tokenSlice = createSlice({
  name: "token",
  initialState: {
    token: null,
    email: null,
    password: null,
    id: null
  },
  reducers: {
    update: (state, action) => {
      state.token = action.payload.token;
      state.email = action.payload.email;
      state.id = action.payload.id;
      state.password = action.payload.password;
    },

    remove: (state, action) => {
      state.token = action.payload.token;
      state.id = "";
      state.email = "";
      state.password = "";
    },
  },
});

export const { update } = tokenSlice.actions;
export const { remove } = tokenSlice.actions;

export default tokenSlice.reducer;
