import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { useLocation } from "react-router-dom";
import AsideMenu from "./AsideMenu/AsideMenu";
import { STRINGS } from "../utils/Enums";
import { useDispatch } from "react-redux";
import { remove } from "../redux/tokenSlice";
import { useNavigate } from "react-router-dom";
const { Sider } = Layout;

const SideBar = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  useEffect(() => {
    // Split the pathnames into an array and remove empty items
    const pathnames = location.pathname.split("/").filter(Boolean);
    // Select the first pathname as the selected key
    setSelectedKeys([pathnames[0]]);
  }, [location]);

  const handleLogout = () => {
    dispatch(remove({ token: null }));
    navigate(STRINGS.ROUTES.LOGIN);
  };

  useEffect(() => {
    // const handleResize = () => {
    if (window.innerWidth < 900) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
    // };

    // window.addEventListener("resize", handleResize);

    // return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  return (
    <Sider
      width={250}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className="logo">
        {!collapsed && (
          <>
            <div style={{ marginTop: "8px" }}>
              <p
                style={{
                  fontSize: "24px",
                  textAlign: "center",
                  fontWeight: "700",
                  fontFamily: STRINGS.FONTS.POPPINS,
                }}
              >
                INSPO
              </p>
            </div>
            <hr />
          </>
        )}
      </div>
      <AsideMenu collapsed={collapsed} />
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <button
          style={{
            background: "white",
            color: "black",
            border: "1px solid black",
            borderRadius: "12px",
            width: "83px",
            height: "24px",
            fontFamily: STRINGS.FONTS.HELVITICA,
            fontSize: "10px",
            fontWeight: "700",
            margin: '0 auto',
          }}
          onClick={() => handleLogout()}
        >
          LOGOUT
        </button>
      </div>
    </Sider>
  );
};

export default SideBar;
