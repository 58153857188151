import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import React, { lazy, Suspense, useState } from "react";
import Loading from "../pages/Loading";
import { STRINGS } from "../utils/Enums";
import MasterLayout from "../layout/masterLayout";
import Approval from "../pages/Approval/Approval";
import { useSelector } from "react-redux";
const Login = lazy(() => import("../pages/Login"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Tables = lazy(() => import("../pages/Tables"));
const Payments = lazy(() => import("../pages/Payments"));
const Forms = lazy(() => import("../pages/Forms"));
const Profile = lazy(() => import("../pages/Profile"));
const Influencer = lazy(() => import("../pages/Influencer/Influencer"));
const InfluencerView = lazy(() => import("../pages/Influencer/InfluencerView"));
const Concept = lazy(() => import("../pages/Concept/Concept"));
const ConceptView = lazy(() => import("../pages/Concept/ConceptView"));
const WithdrawalRequests = lazy(() => import("../pages/Withdrawals/WithdrawalRequests"));
const Notification = lazy(() => import("../pages/Notification/CreateNotification"));
const TableCard = lazy(() => import("../Components/Card/TableCard"));
const CreateUpdateViewCard = lazy(() => import("../Components/Card/CreateUpdateViewCard"));
const InfluencerApprovalTable = lazy(() =>
  import("../pages/InfluencerApproval/InfluencerApproval")
);
const RequestList = lazy(() =>
  import("../pages/Request/RequestList")
);
const RequestDetail = lazy(() =>
  import("../pages/Request/RequestDetails")
);
const ConceptApprovalTable = lazy(() =>
  import("../pages/ConceptApproval/ConceptApproval")
);
const SettingView = lazy(() =>
  import("../pages/Settings/SettingsView")
);

export default function Routing() {
  const token = useSelector((state) => state.token.token);
  const email = useSelector((state) => state.token.email);
  const password = useSelector((state) => state.token.password);
  const id = useSelector((state) => state.token.id);

  const isLogin = () => {
    if (email && password !== null) {
      return true;
    }
  };
  const isAuthenticated = () => {
    return token !== null;
  };


  return (
    <>
      <Router>
        <Routes>
          <Route
            path={`${STRINGS.ROUTES.LOGIN}`}
            element={
              isLogin() ? (
                <>
                  {" "}
                  <MasterLayout>
                    <SuspensedView>
                      <Dashboard />
                    </SuspensedView>
                  </MasterLayout>
                </>
              ) : (
                <>
                  {" "}
                  <SuspensedView>
                    <Login />
                  </SuspensedView>
                </>
              )
            }
          />
          <Route
            path={`${STRINGS.ROUTES.DASHBOARD}`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <Dashboard />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`${STRINGS.ROUTES.INFLUENCER}`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <Influencer />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`${STRINGS.ROUTES.INFLUENCER_VIEW}/:id`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <InfluencerView />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`${STRINGS.ROUTES.CONCEPT}`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <Concept />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`${STRINGS.ROUTES.CONCEPT_VIEW}/:id`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <ConceptView />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`${STRINGS.ROUTES.APPROVAL}`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <Approval />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`${STRINGS.ROUTES.RATINGS}`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <TableCard param={{
                      title: 'Rating',
                      listingApi: `${STRINGS.apiUrl}public/review/listing/review-list`,
                      columnList: [
                        {
                          _id: 'ID'
                        },
                        {
                          'concept.name': 'Concept'
                        },
                        {
                          'influencer.firstName': 'To',
                          'influencer.lastName': ''
                        },
                        {
                          rating: 'Rating'
                        }
                      ],
                      tabListing: [],
                      deleteApi: '',
                      query: { isActive: 'all' }
                    }} />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`${STRINGS.ROUTES.REQUEST}`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <TableCard param={{
                      title: 'Request',
                      listingApi: `${STRINGS.apiUrl}public/request/request-listing`,
                      columnList: [
                        {
                          _id: 'ID'
                        },
                        {
                          'createdAt': 'Date'
                        },
                        {
                          'influencer.firstName': 'Influencer',
                          'influencer.lastName': ''
                        },
                        {
                          'concept.name': 'Concept'
                        },
                        {
                          requestStatusString: true
                        }
                      ],
                      dropDownList: [
                        { key: 'active', label: STRINGS.requestStatusString.active },
                        { key: 'accept__active', label: STRINGS.requestStatusString.accept__active },
                        { key: 'reject', label: STRINGS.requestStatusString.reject },
                        { key: 'accept__accept', label: STRINGS.requestStatusString.accept__accept },
                        { key: 'accept__reject', label: STRINGS.requestStatusString.accept__reject },
                        { key: 'accept__completed', label: STRINGS.requestStatusString.accept__completed },
                        { key: 'accept__not_completed', label: STRINGS.requestStatusString.accept__not_completed },
                        { key: 'completed__completed', label: STRINGS.requestStatusString.completed__completed },
                        { key: 'not_completed__completed', label: STRINGS.requestStatusString.not_completed__completed },
                        { key: 'not_completed__not_completed', label: STRINGS.requestStatusString.not_completed__not_completed },
                        { key: 'not_completed__reject', label: STRINGS.requestStatusString.not_completed__reject },
                        { key: 'not_completed__reject__no_action', label: STRINGS.requestStatusString.not_completed__reject__no_action },
                        { key: 'not_completed__accept', label: STRINGS.requestStatusString.not_completed__accept },
                        { key: 'reject__completed', label: STRINGS.requestStatusString.reject__completed },
                      ],
                      listButton: [
                        {
                          type: 'view',
                          url: '/request/',
                        }
                      ],
                      tabListing: [],
                      deleteApi: '',
                    }} />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`/coverage`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <TableCard param={{
                      title: 'Coverage',
                      listingApi: `${STRINGS.apiUrl}public/request/request-listing`,
                      columnList: [
                        {
                          _id: 'ID'
                        },
                        {
                          'coverage.utcDateTime': 'Date'
                        },
                        {
                          'influencer.firstName': 'Influencer',
                          'influencer.lastName': ''
                        },
                        {
                          'concept.name': 'Concept'
                        },
                        {
                          requestStatusString: true
                        }
                      ],
                      dropDownList: [
                        { key: 'accept__active', label: STRINGS.requestStatusString.accept__active },
                        { key: 'accept__accept', label: STRINGS.requestStatusString.accept__accept },
                        { key: 'accept__reject', label: STRINGS.requestStatusString.accept__reject },
                        { key: 'accept__completed', label: STRINGS.requestStatusString.accept__completed },
                        { key: 'accept__not_completed', label: STRINGS.requestStatusString.accept__not_completed },
                        { key: 'completed__completed', label: STRINGS.requestStatusString.completed__completed },
                        { key: 'not_completed__completed', label: STRINGS.requestStatusString.not_completed__completed },
                        { key: 'not_completed__not_completed', label: STRINGS.requestStatusString.not_completed__not_completed },
                        { key: 'not_completed__reject', label: STRINGS.requestStatusString.not_completed__reject },
                        { key: 'not_completed__reject__no_action', label: STRINGS.requestStatusString.not_completed__reject__no_action },
                        { key: 'not_completed__accept', label: STRINGS.requestStatusString.not_completed__accept },
                        { key: 'reject__completed', label: STRINGS.requestStatusString.reject__completed },
                      ],
                      listButton: [
                        {
                          type: 'view',
                          url: '/request/',
                        }
                      ],
                      tabListing: [],
                      deleteApi: '',
                      query: { coverage: 1 }
                    }} />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`${STRINGS.ROUTES.REQUEST}/:id`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <RequestDetail />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`${STRINGS.ROUTES.WITHDRAWAL}`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <WithdrawalRequests />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`${STRINGS.ROUTES.PAYMENTS}`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <TableCard param={{
                      title: 'Payments',
                      listingApi: `${STRINGS.apiUrl}public/payment/get-payments`,
                      columnList: [
                        {
                          _id: 'ID'
                        },
                        {
                          'concept.name': 'From'
                        },
                        {
                          'influencer.firstName': 'To',
                          'influencer.lastName': ''
                        },

                        {
                          PaymentID: 'PaymentID'
                        },
                        {
                          amount: 'Amount'
                        },
                        {
                          commission: 'Commission'
                        },
                        {
                          payment_date: 'Date'
                        },
                        {
                          paymode: 'Paymode'
                        },
                        {
                          status: 'Status'
                        },
                      ],
                      tabListing: [],
                      deleteApi: '',
                      query: { isActive: 'all' }
                    }} />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`${STRINGS.ROUTES.NOTIFICATION}`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <TableCard param={{
                      title: 'Notification',
                      listingApi: `${STRINGS.apiUrl}public/notification/listing-view/${id}`,
                      columnList: [
                        {
                          _id: 'ID'
                        },
                        {
                          createdAt: 'Date'
                        },
                        {
                          title: 'Title	'
                        },
                        {
                          message: 'Message'
                        }
                      ],
                      button: {
                        title: 'Notify All Users',
                        url: '/notification/create',
                      },
                      tabListing: [],
                      deleteApi: '',
                      query: { isActive: 'all' }
                    }} />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`${STRINGS.ROUTES.NOTIFICATION}/create`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <Notification />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`${STRINGS.ROUTES.SUPPORT}`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <TableCard param={{
                      title: 'Supports',
                      listingApi: `${STRINGS.apiUrl}public/support/get-supports`,
                      columnList: [
                        {
                          _id: 'ID'
                        },
                        {
                          'user.email': 'User Email'
                        },
                        {
                          'user.role.type': 'Role'
                        },
                        {
                          phone: 'Contact No.'
                        },
                        {
                          message: 'Message'
                        },
                        {
                          isContact: ''
                        }
                      ],
                      tabListing: [],
                      toggle: 'isContact',
                      toggleApi: `${STRINGS.apiUrl}public/support/update-contact`,
                      deleteApi: '',
                      query: { isActive: 'all' },
                      dropDownList: [
                        { key: 'contact', label: 'Contact' },
                        { key: 'not_contact', label: 'Not Contact' },
                      ]
                    }} />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`${STRINGS.ROUTES.SETTING}`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <SettingView />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`country`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <TableCard param={{
                      title: 'Country',
                      listingApi: `${STRINGS.apiUrl}public/address/country/listing`,
                      columnList: [
                        {
                          _id: 'ID'
                        },
                        {
                          countryName: 'Country Name'
                        },
                        {
                          niceName: 'Nice Name'
                        },
                        {
                          iso: 'ISO'
                        },
                        {
                          iso3: 'ISO3'
                        },
                        {
                          numCode: 'Number Code'
                        },
                        {
                          phoneCode: 'Phone Code'
                        },
                        {
                          isActive: ''
                        }
                      ],
                      button: {
                        title: 'Create Country',
                        url: '/country/create/new',
                      },
                      listButton: [
                        {
                          type: 'view',
                          url: '/country/view/',
                        },
                        {
                          type: 'update',
                          url: '/country/update/',
                        }
                      ],
                      toggle: 'isActive',
                      toggleApi: `${STRINGS.apiUrl}public/address/country/update-status`,
                      query: { isActive: 'all' }
                    }} />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`country/:type/:id`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <CreateUpdateViewCard cardValue={{
                      title: 'Country',
                      inputList: {
                        countryName: {
                          label: 'Country Name',
                          type: 'text'
                        },
                        niceName: {
                          label: 'Nice Name',
                          type: 'text'
                        },
                        iso: {
                          label: 'ISO',
                          type: 'text'
                        },
                        iso3: {
                          label: 'ISO3',
                          type: 'text'
                        },
                        numCode: {
                          label: 'Number Code',
                          type: 'text'
                        },
                        phoneCode: {
                          label: 'Phone Code',
                          type: 'text'
                        },
                        phoneNoLength: {
                          label: 'Phone No length',
                          type: 'text'
                        },
                      },
                      viewApi: `${STRINGS.apiUrl}public/address/country/view/`,
                      saveApi: `${STRINGS.apiUrl}public/address/country/create`
                    }} />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`governorate`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <TableCard param={{
                      title: 'Governorate',
                      listingApi: `${STRINGS.apiUrl}public/address/governorate/listing/ALL`,
                      columnList: [
                        {
                          _id: 'ID'
                        },
                        {
                          'country.countryName': 'Country Name'
                        },
                        {
                          governorateName: 'Name'
                        },
                        {
                          isActive: ''
                        }
                      ],
                      button: {
                        title: 'Create Governorate',
                        url: '/governorate/create/new',
                      },
                      listButton: [
                        {
                          type: 'view',
                          url: '/governorate/view/',
                        },
                        {
                          type: 'update',
                          url: '/governorate/update/',
                        }
                      ],
                      toggle: 'isActive',
                      toggleApi: `${STRINGS.apiUrl}public/address/governorate/update-status`,

                    }} />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`governorate/:type/:id`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <CreateUpdateViewCard cardValue={{
                      title: 'Governorate',
                      inputList: {
                        country: {
                          label: 'Country',
                          type: 'dropdown',
                          key: '_id',
                          value: 'name',
                        },
                        governorateName: {
                          label: 'Governorate Name',
                          type: 'text'
                        }
                      },
                      viewApi: `${STRINGS.apiUrl}public/address/governorate/view/`,
                      saveApi: `${STRINGS.apiUrl}public/address/governorate/create`,
                      dropdown: {
                        country: {
                          api: `${STRINGS.apiUrl}public/address/country/listing`,
                          dependOn: '',
                          for: ''
                        }
                      }
                    }} />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`areas`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <TableCard param={{
                      title: 'Area',
                      listingApi: `${STRINGS.apiUrl}public/address/area/listing/ALL`,
                      columnList: [
                        {
                          _id: 'ID'
                        },
                        {
                          'governorate.country.countryName': 'Country Name'
                        },
                        {
                          'governorate.governorateName': 'Governorate Name'
                        },
                        {
                          areaName: 'Name'
                        },
                        {
                          isActive: ''
                        }
                      ],
                      button: {
                        title: 'Create Area',
                        url: '/areas/create/new',
                      },
                      listButton: [
                        {
                          type: 'view',
                          url: '/areas/view/',
                        },
                        {
                          type: 'update',
                          url: '/areas/update/',
                        }
                      ],
                      toggle: 'isActive',
                      toggleApi: `${STRINGS.apiUrl}public/address/area/update-status`,
                    }} />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`areas/:type/:id`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <CreateUpdateViewCard cardValue={{
                      title: 'Area',
                      inputList: {
                        country: {
                          label: 'Country',
                          type: 'dropdown',
                          key: '_id',
                          value: 'name',
                        },
                        governorate: {
                          label: 'Governorate',
                          type: 'dropdown',
                          key: '_id',
                          value: 'name',
                        },
                        areaName: {
                          label: 'Area Name',
                          type: 'text'
                        }
                      },
                      viewApi: `${STRINGS.apiUrl}public/address/area/view/`,
                      saveApi: `${STRINGS.apiUrl}public/address/area/create`,
                      dropdown: {
                        country: {
                          api: `${STRINGS.apiUrl}public/address/country/listing`,
                          dependOn: '',
                          for: 'governorate'
                        },
                        governorate: {
                          api: `${STRINGS.apiUrl}public/address/governorate/listing`,
                          dependOn: 'country',
                          for: ''
                        }
                      }
                    }} />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`category`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <TableCard param={{
                      title: 'Category',
                      listingApi: `${STRINGS.apiUrl}public/category`,
                      columnList: [
                        {
                          _id: 'ID'
                        },
                        {
                          'name': 'Name'
                        }
                      ],
                      button: {
                        title: 'Create Category',
                        url: '/category/create/new',
                      },
                      listButton: [
                        {
                          type: 'view',
                          url: '/category/view/',
                        },
                        {
                          type: 'update',
                          url: '/category/update/',
                        }
                      ],
                    }} />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`category/:type/:id`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <CreateUpdateViewCard cardValue={{
                      title: 'category',
                      inputList: {
                        name: {
                          label: 'Category Name',
                          type: 'text'
                        }
                      },
                      viewApi: `${STRINGS.apiUrl}public/category/`,
                      saveApi: `${STRINGS.apiUrl}public/category`,
                    }} />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`subcategories`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <TableCard param={{
                      title: 'Sub Category',
                      listingApi: `${STRINGS.apiUrl}public/category/subcategory/list`,
                      columnList: [
                        {
                          _id: 'ID'
                        },
                        {
                          'categoryName': 'category'
                        },
                        {
                          'subcategoryName': 'Name'
                        }
                      ],
                      button: {
                        title: 'Create Sub Category',
                        url: '/subcategories/create/new',
                      },
                      listButton: [
                        {
                          type: 'delete',
                          api: `${STRINGS.apiUrl}public/category/subcategory/delete`,
                        },
                      ],
                    }} />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
          <Route
            path={`subcategories/:type/:id`}
            element={
              isAuthenticated() ? (
                <MasterLayout>
                  <SuspensedView>
                    <CreateUpdateViewCard cardValue={{
                      title: 'Subcategories',
                      inputList: {
                        category: {
                          label: 'Category',
                          type: 'dropdown',
                          key: '_id',
                          value: 'name',
                        },
                        subcategoryName: {
                          label: 'Subcategories',
                          type: 'text'
                        }
                      },
                      viewApi: `${STRINGS.apiUrl}public/category/subcategory/view/`,
                      saveApi: `${STRINGS.apiUrl}public/category/subcategory/create`,
                      dropdown: {
                        category: {
                          api: `${STRINGS.apiUrl}public/category`,
                          dependOn: '',
                          for: ''
                        }
                      }
                    }} />
                  </SuspensedView>
                </MasterLayout>
              ) : (
                <SuspensedView>
                  <Login />
                </SuspensedView>
              )
            }
          />
        </Routes>
      </Router>
    </>
  );
}

const SuspensedView = ({ children }) => {
  return <Suspense fallback={<Loading />}>{children}</Suspense>;
};
